import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { DatosInmuebleService } from '../../services/datos-inmueble.service';
import { Comentario } from '../../models/comentario.model';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserStorageService } from '../../services/browser-storage.service';
import { PreventNewlineDirective } from '../../directives/prevent-newline.directive';

@Component({
  selector: 'shared-comentarios',
  standalone: true,
  imports: [CommonModule, NgbAccordionModule, ReactiveFormsModule, PreventNewlineDirective],
  templateUrl: './shared-comentarios.component.html',
  styleUrl: './shared-comentarios.component.scss'
})
export class SharedComentariosComponent implements OnInit {
  @Input({required: true}) tipoPersona!: 'interno' | 'externo';
  @Input({required: true}) apiUrl!: string;

  private http: HttpClient = inject(HttpClient);
  private inmuebleService: DatosInmuebleService = inject(DatosInmuebleService);
  private browserStorageService: BrowserStorageService = inject(BrowserStorageService);
  private propiedadId = this.inmuebleService.propiedadId();

  public comentarios: WritableSignal<Comentario[]> = signal([]);
  public mostrarCajaComentario: boolean = false;

  public comentarioFormulario = new FormGroup({
    comentario: new FormControl('', [Validators.required, Validators.maxLength(500)])
  });

  get longitudComentario(): number {
    return this.comentarioFormulario.value.comentario?.length || 0;
  }

  public getComentarios(apiUrl: string) {
    const token = this.browserStorageService.get('token');
    let headers;
    if (token !== null) {
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<Comentario[]>(`${apiUrl}/comentario/visualizar?propiedadId=${this.propiedadId}`, { headers: headers, withCredentials: true});
  }

  public marcarComentarioLeido(comentario: Comentario, apiUrl: string) {
    if (comentario.leido === false) {
      comentario.leido = true;
      const token = this.browserStorageService.get('token');
      const reqBody = [comentario.id];
      let headers;
      if (token !== null) {
        headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      }
      this.http.put(`${apiUrl}/comentario/leido?propiedadId=${this.propiedadId}`, reqBody, { headers: headers, withCredentials: true})
        .subscribe({
          next: (response: any) => {
            console.log('Comentario marcado como leído: ', response);
          },
          error: (error: Error) => {
            console.error(error.message);
            throw new Error('Error al marcar el comentario como leído: ', error);
          }
        });
    }
  }

  public ordenarComentarios(event: Event) {
    const target = event.target as HTMLSelectElement;
    const orden = target.value;
    if (typeof orden === 'string') {
      return this.comentarios.update(comentarios => {
        return comentarios.sort((a, b) => {
          if (orden === 'ascendente') {
            return a.id - b.id;
          } else {
            return b.id - a.id;
          }
        });
      });
    }
  }

  onSubmit() {
    const comentario = this.comentarioFormulario.value.comentario;
    if (typeof comentario === 'string') {
      this.inmuebleService.crearComentario(comentario, this.apiUrl)
      .subscribe({
        next: (response: any) => {
          console.log('Comentario creado correctamente: ', response);
          this.getComentarios(this.apiUrl).subscribe({
            next: (response: any) => {
              this.comentarios.set(response);
            },
            error: (error: Error) => {
              console.error(error.message);
              throw new Error('Error al obtener los comentarios: ', error);
            }
          });
        },
        error: (error: Error) => {
          console.error(error.message);
          throw new Error('Error al crear el comentario: ', error);
        }
      });
      this.comentarioFormulario.reset({ comentario: '' });
      this.mostrarCajaComentario = false;
    }  else {
      console.error('Error en el tipo de comentario: ', comentario);
    }
  }

  ngOnInit(): void {
    this.getComentarios(this.apiUrl).subscribe({
      next: (response: any) => {
        this.comentarios.set(response);
      },
      error: (error: Error) => {
        console.error(error.message);
        throw new Error('Error al obtener los comentarios: ', error);
      }
    });
  }
}
