import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventNewline]',
  standalone: true
})
export class PreventNewlineDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}